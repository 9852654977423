import revive_payload_client_3NdkUqPGhD from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_w6gISoBYFk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NbnwdZFUoV from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_chy3WyFr1R from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_sass@1.78_rhwbgqdzsjxyewlxih7theszay/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import sentry_client_Ub7jFNgAqV from "/opt/buildhome/repo/modules/sentry/runtime/sentry.client.ts";
import payload_client_ispCiWszlx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2C1MczHsm9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_cnMRLEwPp3 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YfUIyQbqqx from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_HcBTexoyQT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_eslint@9.9.1_jiti@1.21._k6dhds2lwztqcr3dlncvshwlpm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_UBHGik3CRN from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_votuvwxbnyf334wjexhjwvskjq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_WkzQ3mSxc6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_sass@1_7d7pnehlswtu7wfhu4xcx5fkae/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_rFEzpN9mOC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-seo-experiments@4.0.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_sass@1_7d7pnehlswtu7wfhu4xcx5fkae/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import umami_wsmGDYyzCw from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-umami@2.6.4/node_modules/nuxt-umami/plugins/umami.ts";
import marker_io_client_3vX6FuptAi from "/opt/buildhome/repo/plugins/marker-io.client.ts";
import defaults_HKtApLuMpH from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.21_h3@1.12.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_votuvwxbnyf334wjexhjwvskjq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_3NdkUqPGhD,
  unhead_w6gISoBYFk,
  router_NbnwdZFUoV,
  _0_siteConfig_chy3WyFr1R,
  sentry_client_Ub7jFNgAqV,
  payload_client_ispCiWszlx,
  navigation_repaint_client_2C1MczHsm9,
  check_outdated_build_client_cnMRLEwPp3,
  chunk_reload_client_YfUIyQbqqx,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HcBTexoyQT,
  titles_UBHGik3CRN,
  siteConfig_WkzQ3mSxc6,
  inferSeoMetaPlugin_rFEzpN9mOC,
  umami_wsmGDYyzCw,
  marker_io_client_3vX6FuptAi,
  defaults_HKtApLuMpH
]